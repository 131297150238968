<div class="wrap" [ngClass]="customClass">
  <div class="input-wrap" [ngClass]="{'input-error': control?.invalid && control?.touched}" (click)="wrapClick()">
    <input #otpInputField id="otpInputField"
      [type]="isMobile ? 'tel' : 'number'"
      placeholder="Enter OTP"
      [formControl]="control"
      (wheel)="$event.preventDefault()"
    />
    <div *ngIf="showResend && !control.disabled" class="resend" (click)="resend()">RESEND OTP</div>
    <div *ngIf="timer && !showResend && !control.disabled" class="resend timer">Resend in {{timer}}</div>
  </div>
  <div class="error">
    <div *ngIf="control?.hasError('required') && control?.touched" [innerHTML]="errorMessage?.required"></div>
    <div *ngIf="!control?.hasError('required') && control?.invalid && control?.touched" [innerHTML]="errorMessage?.pattern"></div>
  </div>
</div>