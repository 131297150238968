import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilService, VariablesService } from 'src/app/shared/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-v2-dock',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home-v2-dock.component.html',
  styleUrls: ['./home-v2-dock.component.scss']
})
export class HomeV2DockComponent {
  dockList = [
    {id: 1, iconName: 'home-dock', label: 'Home', redirectTo: '', isNew: false },
    {id: 1, iconName: 'search-dock', label: 'Explore', redirectTo: '/crowdfunding/fundraisers', isNew: false },
    // {id: 1, iconName: 'hf-dock', label: 'Healthfirst', redirectTo: '/healthfirst', },
    {id: 1, iconName: 'hubble-dock', label: 'Gift Cards', redirectTo: '/new/hubble', isNew: true },
    {id: 1, iconName: 'profile', label: 'Profile', redirectTo: '', isNew: false}
  ];
  constructor(
    private utilService: UtilService,
    private vars: VariablesService,
    private router: Router
  ) {}

  userData;

  ngOnInit() {
    this.userData = this.utilService.getUserData();
  }

  onRedirectionClicked(item) {
    if (this.utilService.isBrowser && item.iconName !== 'home-dock') {
      if (item.iconName === 'profile') {
        this.vars.openDrawerFromDockState.next('open');
        return;
      }
      if (item.iconName === 'home-dock') {
        return;
      }
      if(item.iconName === 'hubble-dock'){
        const isOnboarded = this.userData?.is_onboarded?.hubble ? true : false;
        this.router.navigate([item.redirectTo]);
        return;
      }
      this.router.navigate([item.redirectTo]);
    }
  }
}
