import { ChangeDetectorRef, Component, EventEmitter, inject, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BasicButtonComponent } from '../basic-button/basic-button.component';
import { AuthService, EventsService, SignupService, UtilService, VariablesService } from 'src/app/shared/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { PhoneNumberInputComponent } from '../phone-number-input/phone-number-input.component';
import { AMOUNT_FORM } from 'src/app/shared/models/amount.form';
import { OtpInputComponent } from '../otp-input/otp-input.component';
import { RouterModule } from '@angular/router';
import { getAuth, signInWithPhoneNumber } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { CaptchaComponent } from '../captcha/captcha.component';
import { MatRippleModule } from '@angular/material/core';
import { Subscription } from 'rxjs';


interface IPayload { email?: string, email_id?: string, phone?: string | number, extension?: string, otp?: number, firebase_response?: any, showError?: string, app_name?: string }

@Component({
  selector: 'app-signin-signup',
  standalone: true,
  imports: [CommonModule, RouterModule, ReactiveFormsModule, BasicButtonComponent, PhoneNumberInputComponent, OtpInputComponent, CaptchaComponent, MatRippleModule],
  templateUrl: './signin-signup.component.html',
  styleUrls: ['./signin-signup.component.scss']
})
export class SigninSignupComponent implements OnInit, OnChanges {

  private readonly events = inject(EventsService);

  @Input() title = 'Get verified to see the details'
  @Input() isInline = false;
  @Input() mobileNumber = '';
  @Input() numberOnly = false;
  @Input() emailOnly = false;
  @Input() popupView = false;
  @Input() showPopup = false;
  @Input() hideStartFund = false;
  @Input() noRedirectAfterLogin = false;
  @Input() verifyOnly = false;
  @Output() onClose = new EventEmitter<any>();

  @ViewChild('loginPopup', { static: true }) loginPopup;

  userForm: FormGroup;
  nameControl = new FormControl('', [Validators.required, Validators.minLength(3)]);
  emailControl = new FormControl('', [Validators.required]);
  mobileControl = new FormControl('', [Validators.required]);
  passwordControl = new FormControl('', [Validators.required]);
  otpControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
  otp;

  btnLabel = 'Get OTP';
  isMobile;
  info = AMOUNT_FORM;
  phoneNumber;
  loading = false;
  showOtp = false;
  otpSentTo: string | number;

  payload: IPayload = null;
  showSignupForm = false;

  prefill_script_hit = false;
  showPhoneEdit = false;
  dialogRef: MatDialogRef<any>;
  sheetRef: MatBottomSheetRef<any>;
  allSubs: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private sheet: MatBottomSheet,
    private signupService: SignupService,
    private util: UtilService,
    public vars: VariablesService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.isMobile = this.util.isMobile();
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['showPopup'] && this.popupView && this.showPopup) {
      this.openPopup();
    }
  }

  initForm() {
    this.mobileControl.setValue(this.mobileNumber);
    this.nameControl.addValidators(Validators.pattern(this.vars.name_Pattern_Regex));
    this.emailControl.addValidators(Validators.pattern(this.vars.email_Pattern_Regex));
    this.passwordControl.addValidators(Validators.pattern(this.vars.psw_Pattern_Regex));
    this.otpControl.addValidators(Validators.pattern(this.vars.numberOnlyRegex));
    this.userForm = new FormGroup({
      // name: this.emailControl,
      // email: this.emailControl,
      mobile: this.mobileControl,
    });
  }

  // setSignupControls() {
  //   this.userForm.addControl('password', this.passwordControl);
  // }

  openPopup() {
    if (this.util.isMobile()) {
      this.sheetRef = this.sheet.open(this.loginPopup, {
        panelClass: 'login-signup-sheet'
      });
      this.sheetRef.afterDismissed().subscribe(val => {
        this.resteForm();
      });
    } else {
      this.dialogRef = this.dialog.open(this.loginPopup, {
        width: '528px',
        panelClass: 'login-signup-dialog'
      });
      this.dialogRef.afterClosed().subscribe(val => {
        this.resteForm();
      });
    }
    setTimeout(() => {
      document.getElementById('mobileInputField').focus();
    }, 1000);
  }

  closePopup() {
    if (this.util.isMobile()) {
      this.sheetRef?.dismiss();
    } else {
      this.dialogRef?.close();
    }
  }

  getPayload() {
    let data: IPayload = {
      showError: 'false'
    };
    if (this.numberOnly) {
      data.phone = this.mobileControl.value;
      data.extension = this.phoneNumber?.extn;
    } else if (this.emailOnly) {
      data.email_id = this.emailControl.value;
    }
    return data;
  }

  onSubmit() {
    this.submitForm();
  }

  submitForm() {
    if (this.loading) { return; }
    if (!this.userForm.valid) {
      this.userForm.markAllAsTouched();
      return;
    }
    if (!this.showOtp) {
      this.signupService.sendLoginSystemEvents('Signin Get OTP clicked', {});
      this.verifyUserDetails();
    } else if (this.numberOnly) {
      this.verifyMobileOTP();
    } else {
      this.verifyUserToken();
    }
  }

  verifyUserDetails() {
    this.payload = this.getPayload();
    this.loading = true;
    this.authService.verifyUserDetails(this.payload).subscribe({
      next: (userVerifyRes) => {
        if (this.numberOnly) {
          this.otpSentTo = this.mobileControl.value;
          this.getOTPfromFirebasePhone();
        } else {
          this.loading = false;
          this.toggleOTP(true);
          this.showOtp = true;
          this.btnLabel = 'Login';
          this.util.openSnackBar('OTP has been sent to your email ' + this.emailControl.value, 'success');
          this.focusOtp();
        }
      },
      error: (err) => {
        if (this.numberOnly && (/Invalid details/).test(err?.error?.message)) {
          this.showSignupForm = this.verifyOnly ? false : true;
          this.getOTPfromFirebasePhone();
        } else {
          this.loading = false;
          this.util.openSnackBar(err?.error?.message, 'error');
        }
      }
    });
  }

  editMobileClick = (e?) => {
    this.disableInput(this.mobileControl, false, 'mobile');
    this.toggleOTP(false);
    this.loading = false;
    setTimeout(() => {
      document.getElementById('mobileInputField').focus();
    }, 10);
  }

  disableInput(control: FormControl, disableStatus: boolean, ctrlName = '') {
    if (disableStatus) {
      control.disable();
    } else {
      control.enable();
    }
    if (disableStatus && ctrlName === 'mobile') {
      this.showPhoneEdit = true;
    } else if (ctrlName === 'mobile') {
      this.btnLabel = 'Get OTP';
      this.showPhoneEdit = false;
    }
  }

  getOTPfromFirebasePhone() {
    if (this.util.isBrowser) {
      this.prefillOTP();
      this.disableInput(this.mobileControl, true, 'mobile');
      const appVerifier = (<any>window).recaptchaVerifier;
      const auth = getAuth(initializeApp(environment.firebase));
      signInWithPhoneNumber(auth, this.phoneNumber?.extn + this.mobileControl.value, appVerifier).then(result => {
        if (this.showSignupForm) {
          // this.setSignupControls();
          this.btnLabel = 'Signup';
        } else {
          this.btnLabel = this.verifyOnly ? 'Verify' : 'Login';
        }
        (<any>window).confirmationResult = result;
        this.util.openSnackBar('OTP has been sent to your number ' + this.mobileControl.value, 'success');
        this.toggleOTP(true);
        this.showOtp = true;
        this.loading = false;
        this.focusOtp();
        this.cdr.detectChanges();
      }).catch(error => {
        this.loading = false;
        this.util.openSnackBar(error.message + ': ' + error.code, 'error');
        this.disableInput(this.mobileControl, false, 'mobile');
      });
    }
  }

  resendMobileOtp(e?) {
    if (this.loading) { return; }
    this.otpControl.setValue('');
    this.disableInput(this.otpControl, true, 'otp');
    this.loading = true;
    this.getOTPfromFirebasePhone();
  }

  toggleOTP(e: boolean) {
    this.showOtp = e;
    if (e) {
      this.userForm.addControl('otp', this.otpControl);
      this.disableInput(this.otpControl, false, 'otp');
    } else {
      this.userForm.removeControl('otp');
    }
  }

  prefillOTP() {
    if (typeof window !== 'undefined' && 'OTPCredential' in window && !this.prefill_script_hit && this.util.isMobile()) {
      this.prefill_script_hit = true;
      const ac = new AbortController();
      (<any>navigator).credentials.get({
        otp: { transport: ['sms'] },
        signal: ac.signal
      }).then(otp => {
        this.zone.run(() => {
          this.otp.setValue(otp.code);
          this.submitForm();
        });
        ac.abort();
      });
    }
  }

  verifyMobileOTP() {
    if (this.util.isBrowser) {
      this.disableInput(this.otpControl, true, 'otp');
      this.loading = true;
      (<any>window).confirmationResult.confirm(this.otpControl.value).then(result => {
        this.payload['firebase_response'] = result.user;
        if (this.showSignupForm) {
          this.registerUser();
        } else {
          this.verifyUserToken();
        }
      }).catch(error => {
        this.loading = false;
        this.events.systemEventPush({
          eventName: 'firebase_otp_error',
          info_1: `${this.phoneNumber?.extn}${this.mobileControl.value}`,
          info_2: this.otpControl.value,
          info_3: `${error?.message}`
        });
        this.util.openSnackBar((/invalid-verification-code/).test(error.message) ? 'Invalid OTP' : error.message, 'error');
        this.disableInput(this.otpControl, false, 'otp');
        this.focusOtp();
      });
    }
  }

  verifyUserToken() {
    this.payload.email_id = this.emailControl.value;
    this.payload.otp = +this.otpControl.value;
    this.loading = true;
    if (this.verifyOnly) {
      this.payload.email = this.vars.userData?.value?.email;
      this.payload.phone = '';
      this.payload.app_name = 'default';
      this.verifyOnlyPhone();
      return;
    }
    this.authService.loginOTPVerify(this.payload).subscribe({
      next: (res: any) => {
        this.afterLogin(res);
      },
      error: (error) => {
        this.afterApiError(error?.error?.message);
      }
    });
  }

  afterApiError(msg) {
    this.loading = false;
    this.util.openSnackBar(msg, 'error');
    this.disableInput(this.otpControl, false, 'otp');
    this.focusOtp();
  }

  verifyOnlyPhone() {
    const userData = this.vars.userData.getValue();
    this.authService.verifyPhone(userData.id, this.payload).subscribe({
      next: (res: any) => {
        if (res?.data?.user?.phone_verified_flag) {
          this.vars.userData.value.verified_phone_number = true;
          this.closeDialog();
        } else {
          this.util.openSnackBar('Unable to verify. please try again!', 'error');
        }
      },
      error: (error) => {
        this.events.systemEventPush({
          eventName: 'verify_mobile_error',
          info_1: `${this.phoneNumber?.extn}${this.mobileControl.value}`,
          info_2: this.otpControl.value,
          info_3: error?.error?.message
        });
        this.afterApiError(error?.error?.message);
      }
    });
  }

  registerUser() {
    if (!this.nameControl.value) {
      this.nameControl.setValue('Well Wisher');
    }
    if (!this.emailControl.value) {
      const email = this.phoneNumber?.extn?.replace('+', '') + this.mobileControl.value + this.vars.dummyEmailExtn;
      this.emailControl.setValue(email);
    }
    if (!this.passwordControl.value) {
      this.passwordControl.setValue('Ketto@123');
    }
    const user_payload = {
      full_name: this.nameControl.value,
      email_id: this.emailControl.value,
      extension: this.phoneNumber?.extn,
      phone_1: this.mobileControl.value,
      password: this.passwordControl.value,
      invisible_recaptcha: true,
      user_type: 'individual',
      registration_source: 'direct',
      'g-recaptcha-response': '',
      is_subscribed: '1'
    };

    this.loading = true;

    this.authService.register(user_payload, true).subscribe({
      next: (res: any) => {
        this.afterLogin(res);
      },
      error: (err) => {
        this.util.openSnackBar(err?.error?.message, 'error');
        this.loading = false;
        this.disableInput(this.otpControl, false, 'otp');
        this.focusOtp();
      }
    });
  }

  afterLogin(data) {
    this.vars.loginType = this.showSignupForm ? 'signup' : 'signin';
    this.vars.loginMethod = 'OTP';
    this.signupService.noRedirectAfterLogin = this.noRedirectAfterLogin;
    this.signupService.afterLogin(data);
    this.allSubs.push(this.vars.afterLoginRedirectsHit.subscribe(res => {
      if (res) {
        this.closeDialog();
      }
    }));
  }

  focusOtp() {
    if (this.util.isBrowser) {
      setTimeout(() => {
        document.getElementById('otpInputField').focus();
      }, 10);
    }
  }

  closeDialog() {
    if (this.dialogRef) this.dialogRef?.close();
    if (this.sheetRef) this.sheetRef?.dismiss();
  }

  resteForm = (e?) => {
    this.disableInput(this.mobileControl, false, 'mobile');
    this.toggleOTP(false);
    // this.userForm.reset();
    // this.mobileControl.reset();
    // this.nameControl.reset();
    // this.emailControl.reset();
    this.passwordControl.reset();
    this.loading = false;
    this.onClose.emit(true);
  }

  ngOnDestroy() {
    this.allSubs.forEach(s => s.unsubscribe());
  }

}
