export const AMOUNT_FORM = {
  'mobile': {
    'placeholder': 'Enter Mobile Number',
    'error': {
      'required': 'Please enter your phone number',
      'custom': 'Please enter a valid phone number'
    }
  },
  'mobile_number': {
    'placeholder': 'Enter Mobile Number',
    'popover': {
      'title': 'Enter your contact number',
      'desc': 'Provide an active contact number'
    }
  },
  'name': {
    'placeholder': 'Enter Name',
    'error': {
      'required': 'Minimum 3 characters required',
      'minlength': 'Minimum 3 characters required',
      'maxlength': 'Maximum character limit exceeds',
      'pattern': 'Please enter a valid name'
    }
  },
  'email': {
    'placeholder': 'Enter Email Address',
    'error': {
      'email': 'Please enter a valid email',
      'required': 'Please enter your email',
      'pattern': 'Please enter a valid email'
    }
  },
  'pincode': {
    'placeholder': 'Pincode',
    'error': {
      'minlength': 'Please enter a valid Pincode',
      'maxlength': 'Please enter a valid Pincode',
      'pattern': 'Please enter a valid Pincode'
    }
  },
  'address': {
    'placeholder': 'Address',
    'error': {
      'minlength': 'Please enter a valid Address',
      'maxlength': 'Please enter a valid Address',
    }
  },
  'pancard': {
    'placeholder': 'Pancard',
    'error': {
      'pattern': 'Invalid Pancard Number',
    }
  },
  'passport': {
    'placeholder': 'Passport No.',
    'error': {
      'pattern': 'Invalid passport Number',
    }
  },
  'otp': {
    'placeholder': 'OTP',
    'error': {
      'pattern': 'Invalid OTP Number',
      'required': 'Please enter OTP'
    }
  }
};
