import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/shared/services';
import * as firebase from 'firebase/auth';
import { initializeApp } from 'firebase/app';

@Component({
  selector: 'app-captcha',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit {


  @Input() type;
  @Output() isCaptchaLoaded = new EventEmitter();
  @ViewChild('captcha', { static: true }) captcha;
  public windowRef;
  challengePopup;

  constructor(
    private util: UtilService
  ) { }

  ngOnInit() {
    if (this.util.isBrowser) {
      this.initCaptcha();
    }
  }

  initCaptcha() {
    if (typeof window !== 'undefined') {
      this.windowRef = window;
      const app = initializeApp(environment.firebase);
      const auth = firebase.getAuth(app);
      this.windowRef.recaptchaVerifier = new firebase.RecaptchaVerifier(this.captcha.nativeElement, {
        'size': 'invisible'
      }, auth);
      this.windowRef.recaptchaVerifier.render().then(widgetId => {
        this.windowRef.recaptchaWidgetId = widgetId;
        this.isCaptchaLoaded.next(true);
        this.checkForChallengePopup();
      });
    }
  }

  checkForChallengePopup() {
    if (this.type === 'dialog' && typeof document !== 'undefined') {
      const html: HTMLElement = document.getElementsByTagName('html')[0];
      this.challengePopup = document.querySelector(`[title='recaptcha challenge']`);
      this.challengePopup ? html.style.top = '0px' : setTimeout(() => this.checkForChallengePopup(), 2000);
    }
  }

}
