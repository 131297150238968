import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ScriptLoaderService, UtilService, VariablesService } from 'src/app/shared/services';

interface IErrors {
  required?: string;
  minlength?: string;
  maxlength?: string;
  min?: string;
  max?: string;
  email?: string;
  password?: string;
  pattern?: string;
  custom?: string;
}

@Component({
  selector: 'app-phone-number-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule],
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss']
})
export class PhoneNumberInputComponent implements OnInit, OnDestroy {

  @Input() group: FormGroup;
  @Input() control: FormControl;
  @Input() placeHolder: string;
  @Input() showEdit: boolean = false;
  @Input() errorMessage: IErrors;
  @Input() customClass: string;
  @Input() checkCountry = true;
  @Output() numberEmit = new EventEmitter();
  @Output() onIconClick = new EventEmitter();

  @ViewChild('mobileInput', { static: true }) public mobileInput: ElementRef<HTMLInputElement>;

  country = {
    iso: 'in',
    dialCode: '91',
    number: ''
  };

  iti;
  checkIntlTelErrorSubs;
  isValid;
  isMobile;

  constructor(
    private scriptLoader: ScriptLoaderService,
    private vars: VariablesService,
    private util: UtilService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.util.isMobile();
    this.loadScript();

    this.control.valueChanges.subscribe((val: string) => {
      if (val && !this.vars.numberOnlyRegex.test(val)) {
        const startsWithPlus = val?.startsWith('+');
        const newValue = (startsWithPlus ? '+' : '') + val.replace(/\D/g, '');
        if (newValue !== val) {
          this.control.setValue(newValue, { emitEvent: false });
        }
      // } else if (val?.length <= 10) {
      //   this.control.markAsUntouched();
      }
      this.checkValidation();
    });
  }

  loadScript() {
    this.scriptLoader.load('intl_tel_input').then(() => {
    }).then(() => {
      this.scriptLoader.load('intl_tel_input_util').then((data) => {
        this.intlInputInit();
      }).catch(error => { });
    }).catch(error => { });
  }

  intlInputInit() {
    if (this.util.isBrowser) {
      this.iti = (<any>window).intlTelInput(this.mobileInput.nativeElement, {
        preferredCountries: ['in', 'us', 'gb', 'ae'],
        customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
          return '';
          // return 'e.g. ' + selectedCountryPlaceholder;
        },
      });
      this.setCountry();
      this.onCountryChange();
      this.onMobileNumberChange();
      this.control.markAsUntouched();
      this.checkIntlTelErrorSubs = this.util.checkIntlTelError.subscribe(
        check => {
          if (check === true) {
          }
        }
      );
    }
  }

  onMobileNumberChange() {
    this.mobileInput.nativeElement.addEventListener('change', () => {
      this.updateNumber();
      this.control.setValue(this.control.value.replace(/^0+/, ''));
      console.log('input change is valid = ', this.iti.isValidNumber());
    });
  }

  updateNumber() {
    const data = {
      number: this.iti.getNumber(),
      extn: '+' + this.iti.getSelectedCountryData().dialCode,
      selectedCountry: this.iti.getSelectedCountryData()
    };
    if (data.number.startsWith('+')) {
      data.number = data.number.replace(data.extn, '');
    }
    data.number = data.number.split(' ').join('');
    this.control.setValue(data.number.replace(/^0+/, ''));
    this.util.number.next(data);
    this.numberEmit.emit(data);
  }

  setCountry() {
    const selected_mobile_code = this.util.storage.getFromSession('selected_mobile_code');
    const code = selected_mobile_code || this.util.storage.getPropertyFromSession('iplocation', 'country_code');
    if (code && this.checkCountry) {
      this.iti.setCountry(code);
    }
    this.updateNumber();
  }

  onCountryChange() {
    this.mobileInput.nativeElement.addEventListener('countrychange', () => {
      const countryData = this.iti.getSelectedCountryData();
      this.country['iso'] = countryData.iso2;
      this.country['dialCode'] = countryData.dialCode;
      this.updateNumber();
      this.util.storage.addSessionData('selected_mobile_code', countryData.iso2);
      console.log('countrychange is valid= ', this.iti.isValidNumber());
    });
  }

  checkValidation() {
    if (this.iti.isValidNumber()) {
      this.control.setErrors(null);
    } else {
      this.control.setErrors({ 'incorrect': true });
    }
    console.log('is valid number = ', this.iti.isValidNumber());
  }

  wrapClick() {
    this.mobileInput?.nativeElement?.focus();
  }

  ngOnDestroy() {
    this.mobileInput.nativeElement.click();
    if (this.checkIntlTelErrorSubs) {
      this.checkIntlTelErrorSubs.unsubscribe();
    }
  }
}