<div class="wrap" [ngClass]="customClass">
  <div class="input-wrap" [ngClass]="{'input-error': control?.invalid && control?.touched}" (click)="wrapClick()">
    <div id="intInput"></div>
    <input #mobileInput id="mobileInputField"
      [type]="isMobile ? 'tel' : 'text'"
      [placeholder]="placeHolder"
      [formControl]="control"
      (wheel)="$event.preventDefault()"
      min="0"
    />
    <em *ngIf="!showEdit" class="material-icons phone">phone</em>
    <em *ngIf="showEdit" class="material-icons edit" (click)="onIconClick.emit($event)">edit</em>
  </div>
  <div class="error">
    <div *ngIf="control?.hasError('required') && control?.touched" [innerHTML]="errorMessage?.required"></div>
    <div *ngIf="!control?.hasError('required') && control?.invalid && control?.touched" [innerHTML]="errorMessage?.custom"></div>
  </div>
</div>