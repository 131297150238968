
<button
  [type]="type"
  [ngClass]="class"
  [id]="id"
  [ngStyle]="{'width': themeConfig?.width, 'height': themeConfig?.height, 'background': themeConfig?.borderColor, 'padding': themeConfig?.borderSize, 'box-shadow': themeConfig?.boxShadow, 'border-radius': themeConfig?.borderRadius, 'opacity': disabled?'0.5':'1'}"
  [disabled]="disabled || loading"
  (click)="btnClicked.emit($event)"
  mat-ripple>
  <div *ngIf="!loading"
    [ngStyle]="{'font-size': themeConfig?.fontSize, 'font-family': themeConfig?.fontFamily, 'color': themeConfig?.textColor, 'background': themeConfig?.bgColor, 'letter-spacing': themeConfig?.letterSpacing}">
    <em *ngIf="preIcon" class="material-icons">{{preIcon}}</em> <span [innerHTML]="label"></span> <em *ngIf="postIcon" class="material-icons">{{postIcon}}</em>
  </div>
  <div *ngIf="loading" class="loader"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
</button>
