import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'app-basic-button',
  standalone: true,
  imports: [CommonModule, MatRippleModule],
  templateUrl: './basic-button.component.html',
  styleUrls: ['./basic-button.component.scss']
})
export class BasicButtonComponent {

  @Input() type = 'button';
  @Input() id = '';
  @Input() class = '';
  @Input() disabled;
  @Input() label = '';
  @Input() preIcon = '';
  @Input() postIcon = '';
  @Input() loading = false;
  @Input() themeConfig = {
    textColor: '',
    borderSize: '',
    borderColor: '',
    bgColor: '',
    width: '',
    height: '',
    fontSize: '',
    fontFamily: '',
    letterSpacing: '',
    boxShadow: '',
    borderRadius: ''
  };
  @Output() btnClicked = new EventEmitter();

}
