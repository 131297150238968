import { Component, inject, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ApiService, EventsService, UtilService, VariablesService } from 'src/app/shared/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HubbleOnboardJourneyComponent } from './components/hubble-onboard-journey/hubble-onboard-journey.component';
import { environment } from 'src/environments/environment';
import { IUser } from '../../shared/models/user.model';
import { ISystemEvent } from '../../shared/models/events.model';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hubble-onboard',
  templateUrl: './hubble-onboard.component.html',
  styleUrls: ['./hubble-onboard.component.scss'],
  imports: [CommonModule, MatButtonModule],
  standalone: true
})

export class HubbleOnboardComponent {
  private readonly util = inject(UtilService);
  public readonly vars = inject(VariablesService);
  private readonly api = inject(ApiService);
  private readonly dialog = inject(MatDialog);
  private readonly events = inject(EventsService);
  private readonly actRoute = inject(ActivatedRoute);

  @Input() pageInfo: string = "";
  @Input() designMode: string = '3';
  @Input() cardType: string = 'mini';
  @Output() launchHubble: EventEmitter<any> = new EventEmitter();
  cardImgPath: string = "hubble-v3-landing.png";
  isOnboarded : boolean = false;
  userData : IUser;
  launchDirectly: boolean = false;
  generalSubs: Subscription[] = [];
  params: any;
  dialogRef: MatDialogRef<any>;

  constructor(
  ){}

  ngOnInit(): void {
    this.generalSubs.push(
      this.vars.userData.subscribe((userData: IUser) => {
        this.isOnboarded = userData?.is_onboarded?.hubble ? true : false;
        this.userData = userData;
        if(this.actRoute?.snapshot.data?.launch_Hubble === true) {
          this.launchDirectly = true;
          this.onboardHubble();
          this.generalSubs.push(
            this.util?.hubbleEvent$?.subscribe(event => {
              if(event?.type === 'close'){
                this.util.closeHubble();
                this.util.navigate('');
              }
            })
          );
        };
        this.actRoute.queryParams.subscribe({
          next: (p: any) => {
            if (!p?.onboardHubble && !this.launchDirectly) {
              this.handleQueryParams(p);
            }
          }
        })
      })
    );
    // this.cardType = this.userData?.is_onboarded?.hubble ? 'mini' : '';
    this.cardImgPath = this.designMode === "3" ? "ketto-impact.png" : "ketto-impact-light.png";
  }

  handleQueryParams(params: any) {
    this.params = { ...params };
    if (this.params?.onboardHubble) {
      this.onboardHubble();
    }
  }

  onboardHubble(){
    if(this.isOnboarded){
      this.getSSOToken();
      const payload: ISystemEvent = {
        eventName: (this.launchDirectly) ? 'Hubble Navigation' : 'Card Clicked Hubble',
        event_type: 'campaign',
        page_name: this.vars.pageName,
        info_1: 'Repeat User',
        info_2: this.pageInfo
      };
      this.events.systemEventPush(payload);
    } else {
      if(!this.launchDirectly){
        this.util.router.navigate([], { queryParams: { onboardHubble: 1 }, queryParamsHandling: 'merge' });
      }
      const payload: ISystemEvent = {
        eventName: (this.launchDirectly) ? 'Hubble Navigation' : 'Card Clicked Hubble',
        event_type: 'campaign',
        page_name: this.vars.pageName,
        info_1: 'First time User',
        info_2: this.pageInfo
      };
      this.events.systemEventPush(payload);
      this.dialogRef = this.dialog.open(HubbleOnboardJourneyComponent, {
        width: '100vw',
        maxWidth: '600px',
        height: '100vh',
        maxHeight: this.util.isMobile() ? 'auto' : '600px',
        panelClass: 'hubble-onboard-dialog',
        data: { id: this.dialog, isPhoneVerified : this.userData?.verified_phone_number, launchDirectly: this.launchDirectly },
        autoFocus: true
      });
      this.dialogRef?.afterClosed().subscribe(result => {
        if (result && result?.data?.launchHubble) {
          this.getSSOToken();
        } 
        else {
          this.util.navigate('');
        }
      });
    }
  }

  getSSOToken() {
    if (this.userData) {
      const onboard = this.isOnboarded ? '' : '?onboard=1'
      const url = environment.APP.API_URLS.GET_SSO_TOKEN(this.userData?.id) + onboard;
      this.generalSubs.push(
          this.api.get(url).subscribe({
          next: (resp: any) => {
            if(this.launchDirectly){
              this.util.launchHubble(resp?.data?.token);
            } else {
              this.launchHubble.emit(resp?.data?.token); 
            }
          },
          error: (err: any) => {
            this.util.openSnackBar(err, 'error', {
              duration: 1000
            });
          }
        })
      )
    }
  }

  ngOnDestroy() {
    this.generalSubs.forEach(s => s.unsubscribe());
  }
}
