export interface IInsurance {
  name: string;
  order_id: string;
  entity_id: string;
  extension: string;
  phone: string;
  city: string;
  pincode: string;
  birth_date: string;
  gender: string;
  amount: string;
  insurance_id: string;
  start_date: string;
  end_date: string;
  id: number;
  reattempt: number;
  in_id: number;
  has_disease: number;
  email: string;
  insurance_type?: string;
  plan?: string;
}

export interface ISelectedInsurance {
  vendor: string; // 'bima' | 'medibuddy'
  frequency: string; // 'yearly' | 'monthly'
  plan: string; // 'care' | 'plus' | 'essentials'
  plan_id?: number
  plan_text: string;
  plan_amount: number
  currency: string;
}

export interface ISelectedIllness {
  person: number;
  cover_count?: number;
  cover: string;
  illnessAmount: number;
}

export const InsuranceVendors = {
  bima: { // vendor
    yearly: { // frequency
      care: { plan_id: null, INR: 799 }, plus: { plan_id: null, INR: 1599 } // plans
    },
    monthly: {
      care: { plan_id: null, INR: 99 }, plus: { plan_id: null, INR: 199 } // plans
    }
  },
  medibuddy: { // vendor
    yearly: { // frequency
      essentials: { plan_id: null, INR: 1998 }, plus: { plan_id: null, INR: 3999 } // plans
    }
  }
};

export const MedibuddyPlanName = {
  4716: 'Essentials',
  4717: 'Plus',
  4713: 'Silver',
  1234: 'Mini',
  4682: 'Mini'
}

export const Insurance_Amount = {
  'INR': { min: 50, max: 3500, increment: 100 },
  'GBP': { min: 10, max: 150, increment: 10 },
  'EUR': { min: 10, max: 150, increment: 10 },
  'AED': { min: 10, max: 150, increment: 10 },
  'SAR': { min: 10, max: 150, increment: 10 },
  'USD': { min: 10, max: 150, increment: 10 },
  'SGD': { min: 10, max: 150, increment: 10 },
  'CAD': { min: 10, max: 150, increment: 10 },
  'HKD': { min: 10, max: 150, increment: 10 },
  'QAR': { min: 10, max: 150, increment: 10 },
  'AUD': { min: 10, max: 150, increment: 10 },
  'MYR': { min: 10, max: 150, increment: 10 }
};
