import { Component, OnInit, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { UtilService, EventsService, VariablesService } from 'src/app/shared/services';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISystemEvent } from '../../../../shared/models/events.model';
import { SigninSignupComponent } from 'src/app/core/signin-signup/signin-signup.component';
import { IUser } from '../../../../shared/models/user.model';
import { merge } from 'rxjs';

@Component({
  selector: 'app-hubble-onboard-journey',
  templateUrl: './hubble-onboard-journey.component.html',
  styleUrls: ['./hubble-onboard-journey.component.scss'],
  imports: [CommonModule, MatButtonModule, SigninSignupComponent],
  standalone: true
})
export class HubbleOnboardJourneyComponent implements OnInit {
  activeObj: any;
  intervalId: any;
  currentIndex: number = 0;
  progressWidth: number = 0;
  progressInterval: any;
  isLoading: boolean = false;
  showLaunchBtn: boolean = false;
  loginPopupState: boolean = false;
  content = [
    {
      "title" : "Save lives as you shop",
      "desc" : "Shop gift cards from <span class='bld-cls'>400+ leading brands</span> and <span class='bld-cls'>10%</span> of your purchase will help save lives at no added cost!",
      "imgPath" : "assets/images/hubble/zomato.gif",
      "fallBackImgPath" : "assets/images/hubble/step1-static.png",
      "timeout": 1950,
    },
    {
      "title" : "Save a Life Each Time You Shop",
      "desc" : "Enjoy amazing gifts and <span class='bld-cls'>save lives with every gift card</span> you buy from us.",
      "imgPath" : "assets/images/hubble/hubble-step2.gif",
      "fallBackImgPath": "assets/images/hubble/step2-static.png",
      "timeout": 1200,
    }
    // ,
    // {
    //   "title" : "Be Part of Something Big",
    //   "desc" : "Shop with purpose and <span class='bld-cls'>join a growing community</span> that's saving lives with every gift card they purchase!",
    //   "imgPath" : "assets/images/hubble/hubble-map.gif",
    //   "fallBackImgPath": "assets/images/hubble/hubble-map.gif",
    // },
  ]
  userData: IUser;
  constructor(
    private util: UtilService,
    private vars: VariablesService,
    private events: EventsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<HubbleOnboardJourneyComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ){}

  ngOnInit(): void {
    this.initializeActiveContent();
    this.vars.userData.subscribe((userData: IUser) =>{
      this.userData = userData;
    });
    //this.intervalId = setInterval(() => this.updateContent(), 2000); // Update every 2.5 seconds
  }

  backClicked(){
    if(this.currentIndex === 0){
      this.dialog.closeAll();
      this.util.router.navigate([], {queryParams : { onboardHubble: null}, queryParamsHandling: 'merge'});
    } else {
      this.prevContent();
    }
  }

  signinClosed(event) {
    this.userData = this.vars.userData.getValue();
    this.loginPopupState = false;
    if(this.userData?.verified_phone_number !== null){
      this.dialogRef.close({
        data: {
          launchHubble : true
        }
      });
    }
  }

  private initializeActiveContent(): void {
    if (this.content?.length > 0) {
      this.currentIndex = 0; // Start at the first index
      this.updateActiveContent(); // Initialize the first content
    } else {
      console.error('Content array is empty!');
    }
  }
  
  nextContent(): void {
    // Ensure we don't go beyond the last item
    if (this.currentIndex < this.content.length - 1) {
      if(this.currentIndex === 0){
        const payload: ISystemEvent = {
          eventName: 'Onboarding Hubble',
          event_type: 'campaign',
          page_name: 'Hubble Onboarding Page',
          info_1: 'Step1 Complete',
          info_2: this.dialogData?.launchDirectly ? 'Hubble Navigation' : ''
        };
        this.events.systemEventPush(payload);
      }
      else if(this.currentIndex === 1){
        const payload: ISystemEvent = {
          eventName: 'Onboarding Hubble',
          event_type: 'campaign',
          page_name: 'Hubble Onboarding Page',
          info_1: 'Step2 Complete',
          info_2: this.dialogData?.launchDirectly ? 'Hubble Navigation' : ''
        };
        this.events.systemEventPush(payload);
      }
      this.currentIndex++; // Increment the index
      this.updateActiveContent(); // Update active content
    }
  }
  
  prevContent(): void {
    // Ensure we don't go before the first item
    if (this.currentIndex > 0) {
      this.currentIndex--; // Decrement the index
      this.updateActiveContent(); // Update active content
    }
  }
  
  private updateActiveContent(): void {
    this.showLaunchBtn = (this.currentIndex === this.content?.length - 1) ? true : false;
    if (this.currentIndex >= 0 && this.currentIndex < this.content.length) {
      this.activeObj = { ...this.content[this.currentIndex] }; // Clone the object
      this.progressWidth = 0; // Reset progress
  
      // Ensure the original image is displayed immediately
      if (this.activeObj?.imgPath) {
        this.activeObj.imgPath = this.content[this.currentIndex].imgPath;
      }
  
      // Apply fallback image logic only after the timeout
      if (this.activeObj?.fallBackImgPath) {
        setTimeout(() => {
          this.activeObj.imgPath = this.activeObj.fallBackImgPath;
        }, this.activeObj?.timeout || 2000); // Default to 2 seconds if timeout is not specified
      }
    } else {
      console.error('Invalid index:', this.currentIndex);
    }
  } 

  launchHubble(){
    if(this.dialogData?.isPhoneVerified !== null){
      this.dialogRef.close({
        data: {
          launchHubble : true
        }
      });
    } else {
      this.loginPopupState = true;
    }
  }
}
