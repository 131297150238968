<div class="main-container">
    <div class="content-card">
        <button mat-button class="nav-btn" (click)="backClicked()">
            <span class="material-icons">chevron_left</span>
        </button>
        <div class="heading">
            <div class="top-container">
                <span class="title">introducting</span>
                <img class="birds" src="assets/images/hubble/birds.svg">
            </div>
            <span class="desc">
                Gift Cards
            </span>
        </div>
        <div class="animation-card-gif" *ngIf="activeObj?.imgPath">
            <img class="gif" [src]="activeObj?.imgPath" data-gumlet="false">
        </div>
        <div class="footer">
            <span class="title">{{activeObj?.title}}</span>
            <span class="desc" [innerHTML]="activeObj?.desc"></span>
        </div>
        <div class="action-container">
            <button *ngIf="!showLaunchBtn" mat-button class="next-btn" (click)="nextContent()">
                <span class="material-icons">arrow_forward</span>
            </button>
            <button *ngIf="showLaunchBtn" mat-button class="launch-hubble-btn" (click)="launchHubble()">
                Let's Get Started <span class="material-icons">arrow_forward</span>
            </button>
        </div>
    </div>
    <app-signin-signup
        title="Sign in Ketto to continue"
        [popupView]="true"
        [numberOnly]="true"
        [showPopup]="loginPopupState"
        [noRedirectAfterLogin]="true"
        [hideStartFund]="true"
        [mobileNumber]="userData?.phone_1 || ''"
        [verifyOnly]="true"
        (onClose)="signinClosed($event)"
    />
</div>
