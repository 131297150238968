import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UtilService } from 'src/app/shared/services';
import { AMOUNT_FORM } from 'src/app/shared/models/amount.form';

@Component({
  selector: 'app-otp-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit, OnDestroy {

  @Input() group: FormGroup;
  @Input() control: FormControl;
  @Input() customClass: string;
  @Output() otpEmit = new EventEmitter();
  @Output() onResendClick = new EventEmitter();

  @ViewChild('otpInputField', { static: true }) otpInputField: ElementRef<HTMLInputElement>;

  errorMessage = AMOUNT_FORM.otp.error;
  showResend = false;
  timer = '';
  timerInterval: any;
  isMobile;

  constructor(
    public util: UtilService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.util.isMobile();
    this.control.valueChanges.subscribe((val: string) => {
      if (val?.length > 6) {
        this.control.setValue(val.substring(0, 6));
      }
    });
    this.startTimer();
  }

  wrapClick() {
    this.otpInputField?.nativeElement?.focus();
  }

  startTimer() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    this.showResend = false;
    let duration = 30; // 30 seconds
    this.timerInterval = setInterval(() => {
      let minutes = Math.floor(duration / 60);
      let seconds = duration % 60;
      this.timer = (minutes ? `${minutes}m ` : '') + (seconds ? `${seconds}s` : '');
      if (--duration < 0) {
        this.timer = '';
        this.showResend = true;
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  resend() {
    this.startTimer();
    this.onResendClick.emit(true);
  }

  ngOnDestroy(): void {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

}
