<div class="main-container" *ngIf="!launchDirectly">
    <div class="card" *ngIf="cardType === '' && !isOnboarded" [ngClass]="designMode === '3' ? 'bg-blue' : 'bg-default'" (click)="onboardHubble()">
        <div class="impact-image" [ngStyle]="{'background-image': 'url(assets/images/hubble/' + cardImgPath + ')'}">
            <div class="top-info">
                <span class="el1" [ngClass]="{'fc-wh': designMode === '3'}">introducing</span>
                <span class="el2" [ngClass]="{'fc-wh': designMode === '3'}">Gift Cards</span>
                <span class="el3" [ngClass]="{'fc-wh': designMode === '3'}">that give back</span>
            </div>
            <div class="bottom-info">
                <span class="left-content" [ngClass]="{'fc-wh': designMode === '3'}">
                    Save a life with <span class="el1" [ngClass]="{'fc-wh': designMode === '3'}">every gift card </span>you buy.
                </span>
                <button mat-button class="right-content btn" [ngClass]="{'fc-wh': designMode === '3','white-btn': designMode === '3'}">
                    Save a life today<span class="material-icons">arrow_forward</span>
                </button>
            </div>
        </div>
    </div>
    <div class="mini-card" *ngIf="cardType === 'mini' || isOnboarded" [ngClass]="designMode === '3' ? 'bg-blue' : 'bg-default'" (click)="onboardHubble()"> 
        <div class="default-info" *ngIf="!isOnboarded">
            <div class="content">
                <span class="title">
                    introducing
                </span>
                <span class="subtitle">
                    <span>Gift Cards</span><span class="material-icons">arrow_forward</span>
                </span>
            </div>
            <span class="desc">
                Save a life with <span class="italic">every gift card </span>you buy.
            </span>
        </div>
        <div class="onboarded-info" *ngIf="isOnboarded">
            <div class="content">
                <span class="title">
                    Continue saving lives
                </span>
                <span class="desc">
                    Get another gift card
                </span>
            </div>
            <div class="arrow-icon">
                <span class="material-icons">arrow_forward</span>
            </div>
        </div>
        <div class="impact-mini-image" [ngStyle]="{'background-image': 'url(assets/images/hubble/ketto-impact-mini.png)'}">
        </div>
    </div>
</div>