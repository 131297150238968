<div class="dock-wrapper">
  <div class="dock-list">
    <div class="dock-item" *ngFor="let dockItem of dockList" (click)="onRedirectionClicked(dockItem)">
      <div class="dock-icon">
        <img [src]="userData?.avtar?.cdn_path" *ngIf="dockItem.label === 'Profile' && userData?.avtar" alt="profile avatar" />
        <i class="icon-user1" *ngIf="dockItem.label === 'Profile' && !userData?.avtar"></i>
        <img [src]="'assets/images/home-v2/' + dockItem.iconName +'.png'" *ngIf="dockItem.label !== 'Profile'" alt="dock icon" />
        <span class="new-label" *ngIf="dockItem?.isNew">
          NEW
        </span>
      </div>
      <div class="dock-label">
        {{dockItem.label}}
      </div>
    </div>
  </div>
</div>