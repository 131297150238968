<ng-template *ngIf="!popupView" [ngTemplateOutlet]="loginPopup" />

<ng-template #loginPopup>
  <div class="wrap" [ngClass]="{'in-popup': popupView, 'inlineLogin': isInline}">
    <div *ngIf="popupView" class="close-btn desk-only" (click)="closePopup()"><em class="material-icons">close</em></div>
    <div class="content">
      <div *ngIf="!isInline" class="head">
        <div class="title">{{title}}</div>
      </div>
      <div class="form-section">
        <form *ngIf="userForm" [formGroup]="userForm" (ngSubmit)="onSubmit()">
          <div class="input-fields">
            <div class="field" [ngClass]="{'d-none': isInline && showOtp}">
              <div class="mobile" [ngClass]="{'otp-sent': showOtp}">
                <app-phone-number-input
                  [control]="mobileControl"
                  [group]="userForm"
                  [placeHolder]="info.mobile.placeholder"
                  [showEdit]="showPhoneEdit"
                  [errorMessage]="info.mobile.error"
                  (numberEmit)="phoneNumber=$event"
                  (onIconClick)="editMobileClick($event)"
                />
              </div>
            </div>
            <div *ngIf="showOtp" class="field">
              <div class="otp">
                <app-otp-input
                  [control]="otpControl"
                  [group]="userForm"
                  (otpEmit)="otp=$event"
                  (onResendClick)="resendMobileOtp($event)"
                />
              </div>
            </div>
          </div>
          <div class="actBtn">
            <button *ngIf="isInline" type="submit" [disabled]="loading" mat-ripple>
              <div *ngIf="!loading" class="text"><span>{{showOtp ? btnLabel: 'Continue'}}</span><em *ngIf="!showOtp" class="material-icons">arrow_forward</em></div>
              <div *ngIf="loading" class="loader"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
            </button>
            <app-basic-button *ngIf="!isInline" type="submit" [label]="btnLabel" [loading]="loading" [themeConfig]="{width: '100%', height: '38px', fontSize: '14px', letterSpacing: '-0.28px', borderSize: '0', bgColor: '#01BFBD', textColor: '#FCFCFC', boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.24)'}" />
          </div>
          <div *ngIf="isInline && showOtp" class="resendEdit">
            <div class="resend" (click)="resendMobileOtp()" mat-ripple>RESEND OTP</div><span></span><div class="edit" (click)="editMobileClick()" mat-ripple>EDIT NUMBER</div>
          </div>
        </form>
      </div>
      <div *ngIf="!isInline" class="terms-n-policy"> By continuing you are agreeing to our
        <a rel="noopener" href="https://www.ketto.org/privacy-policy.php" target="_blank">Privacy Policy</a> and
        <a rel="noopener" href="https://www.ketto.org/terms-of-use.php" target="_blank">Terms of Service</a>
      </div>
      <div *ngIf="!isInline && !hideStartFund" class="startFund">Want to start a fundraiser?<a [routerLink]="'/new/crowdfunding'" (click)="closePopup()">Click here</a></div>
    </div>
  </div>
</ng-template>

<app-captcha />
